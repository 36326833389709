import React from "react"

import Layout from "../../components/layout"
import * as styles from '../../styles/pages/about-renovation.module.css'

import image_banner from '../../images/about/renovation_banner.jpg'
import image_tower from '../../images/about/renovation_tower.png'

const AboutRenovationsPage = () => (
  <Layout activePath='about' fullPath='about/renovations' pageTitle="Renovations">
  	<div className="bg-cement-tile">
  		<br/>
	    <h1 class='font-size-large header-title fullblock-mobile-only'>Renovations</h1>
  		<br/>
	  	<div className="margin-content">
	  		<img className={styles.banner} src={image_banner} alt="" />
			<h2 className="font-size-huge font-style-sectiontitle">Build a Better World</h2>

			<p>Long before the Roman Catholic Church in the Philippines knew that Pope Francis would visit the country in January this year, the Rector of the Manila Cathedral Msrg. Nector Cerbo asked Engr. Teresito (Terry) Malicse to waterproof a mezzanine floor with a critical challenge of ensuring that there would be no noticeable physical changes while preserving the centuries old floor tiles.</p>

			<p>It took forty people and two years to do the job. But that was because as the waterproofing work progressed, Engr. Malicse was asked to be involved in the thorough restoration of the church. The scope of work extended not only in the waterproofing of the gutters and the roof decks but also in the restoration of the side walls of the building, main face, Belfry Tower, copper doors, columns, main central dome, painting of chapels, and the entire interior of the cathedral. The work on the Belfry Tower and the Façade focused on the restoration of the adobe stones. It turned out to be a painstaking task because existing materials had to undergo historic restoration, to ensure the preservation of its historic significance.</p>

			<p>How many millions witnessed the first Holy Mass at the Manila Cathedral celebrated by Pope Francis, or Lolo Kiko, as the Filipinos fondly called him? It was in these glorious surroundings that people laughed when the pontiff started his homily with "Do you love me," and the audience said, "Yes."</p>

			<p>So, who is this man responsible for restoring the noble stone backdrop that embraced the grandeur of Lolo Kiko's message? Who is Engr. Terry Malicse?</p>

			<p>A slight man with an unassuming air, Terry is highly regarded in the worlds of mining and construction. His company Tertex International Philippines, Inc. is an innovative solutions provider for construction and mineral processing technology. His creative talent lies in his perspicacity in finding practical applications for new technology, or its combinations, for use in the construction of residential and commercial buildings, industrial warehouses, agricultural buildings, water reservoirs, water retaining structures, driveways, sea ports, roads and bridges, as well as in the restorations of heritage sites.</p>

			<p>For the painting of the interior of Manila Cathedral, Tertex tested many materials but decided to use BOYSEN<span className='registeredicon'>&reg;</span>'s Konstrukt&trade; K-222 Interior Render because it matched the authentic texture finish and color which the restoration architect specified. Also, the product has excellent adhesion to the substrate which is important in such a delicate project.</p>

			<p>Engr. Malicse said, "The number of hours in a working day is not enough to do everything I want to do. But I've found a way to increase those working hours in a day to a hundred." A mischievous smile appeared when he got perplexed looks. "Develop a team who can build on your vision," he said. He now mentors a tight-knit group of engineers and architects, once bright students in his province of Bohol and Cebu, whom he supported through college. They form a cohesive group implementing construction projects, many of which are landmarks.</p>

			<p>Traveling on an APEC Business Travel Card visa to have easier access to all APEC countries, Engr. Malicse travels regularly to different countries in search for new technology and innovative material, to visit his material suppliers, to give talks to various international organizations about his projects and findings on raw materials technologies, and in those venues, exchange ideas with experts from the different parts of the world about their best practices.</p>

					<p className={styles.towertexta}>After he saw the damage brought about by the 2013 earthquakes in Bohol, Engr. Malicse together with his wife Marnie and daughter Sheelah developed a housing innovation called the Heritage House System, which now has a prototype called Earthblock House in Barrio Aguipo, Mabini, Bohol.</p>

					<p className={styles.towertextb}>"Heritage House" is a long-term, durable, affordable and customizable housing structure following timeless architectural principles with a foundation that is stable. It has good thermal insulation, is energy efficient, and utilizes the earth and clay material where the house is built. It is designed to have a good aerodynamics property particularly for areas affected by strong typhoons. According to the company's press release, this structure is designed to withstand an 8.2 earthquake magnitude, is typhoon-resistant, affordable, safe, sustainable, and can be easily built by ordinary people in a matter of days.</p>

			<div className={styles.tower}>
				<div className={styles.towertext}>
					<p className={styles.towertextc}>During his visit, Pope Francis said, "What is the most important subject you have to learn in life? To learn how to love. This is the challenge that life offers you: to learn how to love. Not just to accumulate information without knowing what to do with it. But through that love let that information bear fruit." It seems that Terry had heard this call long before it was said. In his unceasing search for new technologies, Engr. Malicse does not let information stop with him, instead he transforms it into something that the world can use. He finds answers to the what, when, where and how of his business.</p>

					<p className={styles.towertextd}>And the why is pure Malicse: he wants to see a better world out there, literally and figuratively.&nbsp;<span className="color-text-primary">&#9607;</span></p>
				</div>
		  		<img className={styles.tower} src={image_tower} alt="" />
		  	</div>
		</div>
	</div>
  </Layout>
)

export default AboutRenovationsPage
